import VueConfirmDialog from 'vue-confirm-dialog/src'
import CoreuiVue from "@coreui/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "core-js/stable";
import JwPagination from "jw-vue-pagination";
import velocity from "velocity-animate";
import Vue from "vue";
import VueExcelXlsx from "vue-excel-xlsx";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import CommonJS from "./assets/common";
import { iconsSet as icons } from "./assets/icons/icons.js";
import installElement from "./services/installElement.js";
import router from "./services/router";
import store from "./services/store";


library.add(fab, fas);
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.prototype.$http = Axios;
Vue.config.productionTip = false;
Vue.use(installElement);
Vue.use(CoreuiVue);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications, { velocity });
Vue.use(CommonJS);
Vue.use(VueExcelXlsx);
Vue.component("jw-pagination", JwPagination);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  icons,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
