import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../components/Login/Login.vue"),
  },
  {
    path: "/admin",
    redirect: "/home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../containers/TheContainer.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../components/Dashboard/dashboard.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "userlist",
        name: "User List",
        component: () => import("../components/Users/list-users.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "adduser",
        name: "Add User",
        component: () => import("../components/Users/add-users.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "edituser/:id",
        name: "EditUser",
        component: () => import("../components/Users/add-users.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "vocabularylist",
        name: "Vocabulary List",
        component: () => import("../components/Vocabulary/list-vocabulary.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/vocabularydetails/:id",
        name: "Vocabulary Details",
        component: () =>
          import("../components/Vocabulary/vocabulary-details.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});
export default router;
