const CommonJS = {
  install: function (Vue) {
    // Vue.alert = Vue.prototype.$alert = function(src) {
    //   this.$dialog.alert(src);
    // };
    Vue.successAlert = Vue.prototype.$successAlert = function (msg) {
      this.$notify({
        type: "success",
        title: "Success",
        text: msg,
        'animation-type': 'velocity'
      });
    };

    Vue.dangerAlert = Vue.prototype.$dangerAlert = function (msg) {
      this.$notify({
        type: "error",
        title: "Error",
        text: msg,
        'animation-type': 'velocity'
      });
    };

    Vue.formatDate = Vue.prototype.$formatDate = function formatDate(date) {
      if (date != null && date != "" && date != undefined) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }
      return "";
    }

    Vue.formatDatePicker = Vue.prototype.$formatDatePicker = function formatDatePicker(date) {
      if (date != null && date != "" && date != undefined) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }
      return "";
    }
  }
};
export default CommonJS;