<template>
  <div>
    <router-view> </router-view>
<vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";

.is-invalid .vs__search::placeholder,
.is-invalid .vs__dropdown-toggle,
.is-invalid .vs__dropdown-menu {
  border-color: #e55353;
}
</style>
<style>
table {
  min-height: 300px;
}
</style>