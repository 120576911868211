import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_URL;

var state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  apiURL: API_URL,
  status: "",
  loader: false,
  projecttitle: "CUUniversity",
};
const userInfo = {};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state) {
    state.status = "success";
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.userInfo = {};
  },
  showLoader(state) {
    state.loader = true;
  },
  hideLoader(state) {
    state.loader = false;
  },
  setUserInfo(state, userinfo) {
    state.userInfo = userinfo;
  },
};

export default new Vuex.Store({
  state: state,
  plugins: [createPersistedState({ paths: ["userInfo", "navItems"] })],
  modules: {
    userInfo,
  },
  mutations,
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({ url: state.apiURL + "/auth/login", data: user, method: "POST" })
          .then((resp) => {
            console.log(resp);
            var _userdata = resp.data.data;
            let userinfo = {};
            userinfo.userId = _userdata.user._id;
            userinfo.userType = _userdata.user.userType;
            userinfo.email = _userdata.user.email;
            userinfo.token = resp.data.data.token;
            commit("setUserInfo", userinfo);

            axios.defaults.headers.common[
              "authorization"
            ] = `Bearer ${resp.data.data.token}`;
            commit("auth_success");
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            resolve(err.response);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        delete axios.defaults.headers.common["authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.userInfo.token,
    authStatus: (state) => state.status,
  },
});
